import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0045 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="中小企業も適用に！月60時間超残業の割増賃金率引き上げについて" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.02.27</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        2023年4月以降、
        <span>
          中小企業でも月に60時間を超える時間外労働を行う従業員に対して割増率が引き上げられます。
        </span>
        実務上、既に大企業は対象とされていますが、中小企業にも拡大されます。今回は「月60時間超残業の割増賃金率引き上げ」にフォーカスをあて、解説します。
      </p>
      <img src="/images/column/details/c0045.jpg" alt="キンクラコラム画像45" />

      <h1 id="toc1">変更点は何か？</h1>
      <p>
        最大の注意点は割増賃金の率が上昇することです。旧来、中小企業は月に60時間超の時間外労働を行っても25％の割増率が「最低基準」とされていましたが、
        <span>2023年4月以降は50％の割増率が「最低基準」となります。</span>
        大企業は2010年に既に施行されていましたが、中小企業もいよいよ2023年4月に施行されることとなります。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc2">改正に向けて注意したいポイント</h1>
      <h2 id="toc2-1">
        深夜と休日が重なると賃金はどういった計算方法になるか？
      </h2>
      <p>
        深夜とは22時から翌朝5時までの特定の時間帯を指します。時間外労働の場合は法定労働時間とされる1日8時間、1週間40時間を超えた場合に法的な時間外労働（いわゆる残業）として扱われますが、深夜の場合は時間の長さではない点が時間外労働との最大の相違点となります。次に休日には法定休日と法定外休日があります。労働基準法上、原則として週に1日は休日を設けることが「最低基準」とされています。昨今、「週休2日」が普及しており、例えば土日が休みの企業の場合、日曜日を法定休日とする企業も多くありますが、法定休日は特定することまでは求められていません(あくまで特定することが望ましい)。
        <br />
        <br />
        まず、深夜に労働した場合、
        <span>
          25％の割増が「最低基準」となり、法定休日労働については35％の割増が「最低基準」となります
        </span>
        （法定外休日については休日割増の義務はありません）。よって、深夜かつ法定休日労働した場合に目を向けると（例えば日曜日が法定休日の会社で土曜日の深夜から日曜日の明け方に労働）深夜割増の25％＋休日割増の35％を合算し、60％の割増が「最低基準」となります。
        <br />
        <br />
        補足として、時間外労働と深夜が重なることはあります。例えば時間外労働を命じられた従業員がそのまま深夜労働に入った場合、時間外労働の割増率25％＋深夜割増の25％を合算し、50％の割増率が「最低基準」となります。他方、時間外労働と休日労働は重なることはありません。繰り返しになりますが、
        <span>
          時間外労働は「時間の長さ」に対して対価として保障をするのに対し、休日労働は「特定の日」に働かせたことに対して対価として保障をすると考えられるため、重なることはありません。
        </span>
      </p>
      <h2 id="toc2-2">正社員以外の労働者の場合はどうなるのか？</h2>
      <p>
        正社員以外の雇用形態(例えばアルバイト)であっても、月に60時間超の時間外労働が発生した場合は正社員同様に25％ではなく、50％の割増率が「最低基準」となります。割増賃金は強行法規とされる労働基準法に根拠規定がおかれ、仮に従業員であるアルバイトと月に60時間超の時間外労働が発生したとしても割増率は25％でよいとの合意を締結したとしても無効と解されます。
      </p>
      <h2 id="toc2-3">代替休暇の付与することも可能に</h2>
      <p>
        引き上げされた割増賃金を支払う代替措置として労使協定を締結し、代替休暇を付与する選択肢も認められています。ただし、制度として導入したとしてもあくまで選択するか否かは従業員の意思に委ねられます。代替休暇の単位は1日または半日で与えることも可能です。
      </p>

      <h1 id="toc3">法改正までに企業がすべきことは？</h1>
      <h2 id="toc3-1">就業規則の変更や代替休暇制度の新設</h2>
      <p>
        まずは今回の改正を契機に確認的な意味合いで
        <span>就業規則に時間外労働の定義を明確化しておくことが</span>
        適切です。労働時間の定義は過去の労働判例上も「使用者からの指揮命令下にある時間」とされています。すなわち、単に事業場内に「滞留」している時間は労働時間ではありませんので、労使双方で認識合わせをしておくべき部分です。今後は月に60時間超の時間外労働が生じた場合、これまでよりも経営的な問題に発展してしまうリスクをはらんでいます。
        <br />
        <br />
        次に<span>代替休暇制度の新設</span>
        です。仮に従業員が希望しなかった場合は改正後の割増率で支給せざるを得ませんが、選択肢として残すことで将来的には活用が可能と言えます。そもそも月に60時間超の時間外労働が生じる時点で多忙な部門ということは容易に想像がつきますので、取得可能期間は60時間超の時間外労働が生じた月の末日の翌日から2か月以内の期間で与えることとされています。例えば4月に生じた場合は5～6月が取得可能という理解です。
      </p>
      <h2 id="toc3-2">勤怠管理システムの整備</h2>
      <p>
        <span>勤怠管理システムの整備は必須</span>
        と言えます。一例としてアラート機能です。月に60時間を超えてからようやく事態を把握できる状態では、従業員への健康被害を防ぐことが難しくなります。また、代替休暇を取得するにしても各従業員のシフトや人員を把握できなければいつ、どの程度の取得であれば事業の正常な運営に支障をきたさないかの判断がつきません。もちろん、紙での管理が不可能ということはありませんが、適宜情報を更新し、迅速に事業場内全体の情報を把握するには、検索性に優れたシステムの導入は必要なコストと考えます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0045
